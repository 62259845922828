.topBar {
    padding: 0.5rem 1rem;
    background: var(--horizontalMenu-background);
}
.topBar .appLogo {
    height: 70px;
}
.topBar__endContent {
    display: flex;
    gap: 24px;
    align-items: center;
}
.topBar__profileBtn {
    display: flex;
    gap: 14px;
}
.topBar__icon {
    font-size: 1.3rem;
    color: var(--text-color-secondary)
}
.topBar__menuProfileItem {
    display: flex;
    gap: 7px;
    padding: 0.75rem 1rem;

    .p-avatar {
        width: 40px;
        height: 40px;
        flex: 0 0 auto;
    }

    .userInfo {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 120px;
    }

    span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .userName {
        font-weight: bold;
    }
}
.topBar__menuProfile {
    .p-menu-separator {
        border-color: var(--menu-borderColor);
    }
}

.topBar__menuCompanyItem {
    margin: 0;
    padding: 0.55rem 1rem 0.75rem 1rem;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    display: block;

    &[data-active="true"] {
        color: var(--primary-text);
    }

    &[data-active="false"] {
        &:hover {
            cursor: pointer;
        }
    }
    
    i {
        margin-right: 7px;
    }
}

@media (max-width: 500px) {
    .topBar__endContent {
        flex-wrap: wrap;
    }
}