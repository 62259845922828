.appForm {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}
.appForm__field {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 5px 0;
  width: 100%;
  
  &.row {
    flex-direction: row;
    align-items: center;
  }

  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    
    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 10px;
    }

    &.p-error {
      color: var(--message-error-color)
    }
  }

  small {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  &.datepicker, &.formfieldrepeater, &.datepickerrange, &.fileupload {
    .p-button:not(.p-button-danger) {
      background: var(--button-secondary-background);
      border: 1px solid var(--button-secondary-borderColor);
    }
    .p-button:not(:disabled, .p-button-danger):hover {
      background: var(--button-secondary-borderColor);
    }
  }

  input[disabled], div.p-disabled:not(.p-inputswitch) {
    background-color: #e3e3e3;
  }

  &.table, &.criteria_table {
    div.addNewTableRow {
      text-align: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }
    }

    :where(table) {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      text-indent: 0;
      border-right: 1px solid var(--table-border-color);
    }

    td,
    th {
      padding: 10px;
      border-top: 1px solid var(--table-border-color);
      border-bottom: 1px solid var(--table-border-color);
      border-left: 1px solid var(--table-border-color);
      background-color: white;
      color: var(--global-textColor);
      font-size: 15px;
      text-align: left;
    }

    th {
      padding: 15px 10px;
      font-weight: bold;
    }

    td {
      min-width: 120px;
      input {
        width: 100%;
      }
    }

    tfoot td {
      border-top: 1px solid var(--table-border-color);
      border-top: 1px solid var(--table-border-color);
    }

    table.striped tbody tr:nth-child(odd) td,
    table.striped tbody tr:nth-child(odd) th {
      background-color: var(--table-border-color)
    }
  }
}

.appForm__field--required.appForm__field--required {
  margin-left: 2px;
  color: var(--message-error-color);
}

.appForm__fieldItem {
  display: flex;
  gap: 0.5rem;
}

.appForm__fileUploadItem {
  display: grid;
  grid-template-columns: 2fr 0.5fr 0.5fr;

  > div {
    display: flex;
    align-items: center;

    &:nth-of-type(2) {
      justify-content: center;
    }

    &:nth-of-type(3) {
      justify-content: flex-end;
    }
  }
}

.appForm__fileUploadItemName {
  color: var(--global-textColor);
  font-style: italic;
  text-align: left;
}

.appForm__row {
  display: flex;
  gap: 7px;
  align-items: center;
}

.appForm__col {
  display: flex;
  flex-direction: column;
  gap: 7px;

  label {
    font-weight: 400;
  }
}

.appForm__cols {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  > div:not(.appForm__field) {
    label {
      font-weight: 400;
    }
  }
}

.appForm__switchFieldWrapper {
  display: flex;
  gap: 1em;
  margin-bottom: 20px;
  
  .appForm__field.switch {
    width: 90px;
  }
  
  > div:nth-of-type(2) {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}

.appForm__repeaterItem {
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  border-left: 3px solid #dadada;


  &:hover {
    border-color: var(--button-secondary-background);
    background: #FCF7E7;
  }
}

.appForm__faqHeaderControls {
  display: flex;
  gap: 1rem;

  button {
    flex: 0 0 auto;
  }

  > div {
    flex: 1 1 auto;
  }
}

.appForm__faqTab {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.appForm__faqTabItem {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  .p-togglebutton[data-p-highlight="true"] {
    .p-button:not(.p-button-danger) {
      background: var(--menuitem-active-background);
      border: 1px solid var(--menuitem-active-background);
    }
  }
}

.appForm__content {
  /*p {
    margin: 0;

    &.ql-indent-1 {
      padding-left: 3em;
    }
    &.ql-indent-2 {
      padding-left: 6em;
    }
    &.ql-indent-3 {
      padding-left: 9em;
    }
    &.ql-indent-4 {
      padding-left: 12em;
    }
    &.ql-indent-5 {
      padding-left: 15em;
    }
    &.ql-indent-6 {
      padding-left: 18em;
    }
    &.ql-indent-7 {
      padding-left: 21em;
    }
  }

  h2 {
    margin-bottom: 15px;
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }

  li[data-list="bullet"] {
    list-style: disc;
  }*/
}

.appForm__delegaForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  gap: 40px;

  legend {
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .appForm__cols {
    gap: 2em;
  }
}

.appForm__delegaFormHeader {
  text-align: center;
}

.appForm__delegaFormActions {
  display: flex;
  justify-content: flex-start;
  gap: 0.5em;
  width: 100%;
}