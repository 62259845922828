.p-steps {
  .p-highlight {
    span.p-steps-number {
      color: var(--menuitem-active-color);
    }
  }
}
.p-badge {
  color: var(--menuitem-active-color);
}
.p-button:not(.p-button-outlined, .p-button-secondary, .p-confirm-popup-reject, .p-button-link, .p-column-filter-add-button, .p-column-filter-remove-button),
.p-button:not(.p-button-outlined, .p-button-secondary, .p-confirm-popup-reject, .p-button-link, .p-column-filter-add-button, .p-column-filter-remove-button) span {
  color: var(--menuitem-active-color);
}
.p-button.p-button-success.p-button-icon-only[disabled],
.p-button.p-button-danger.p-button-icon-only[disabled] {
  filter: unset;
}
.p-column-filter-remove-button {
  span {
    margin: 0 5px;
  }
}
.p-fileupload-row {
  .p-button-danger {
    background-color: #f44336;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
}
.p-tag {
  &.p-tag-secondary {
    background-color: var(--table-border-color);
  }

  &.p-tag-tertiary {
    background-color: var(--card-full-background-color-6);
  }

  .p-tag-value {
    color: var(--menuitem-active-color);
  }
}

.p-paginator-page {
  /*color: var(--menuitem-active-color);*/
}

.p-datepicker-buttonbar .p-button {
  color: var(--global-textColor);
}

.p-message-wrapper {
  .p-message-close {
    flex: 0 0 auto;
    margin-left: 10px;
  }
}

.p-message.p-message-error {
  background: var(--message-error-background);
  border-left: 5px solid var(--message-error-color);

  span.p-message-detail, span.p-message-summary {
    color: var(--message-error-color);
  }

  svg {
    path {
      fill: var(--message-error-color);
    }
  }
}

.p-message.p-message-info {
  background: var(--message-info-background);
  border-left: 5px solid var(--message-info-color);

  span.p-message-detail, span.p-message-summary {
    color: var(--message-info-color);
  }

  svg {
    path {
      fill: var(--message-info-color);
    }
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: #C9E3CC;
}

.p-accordion {
  width: 100%;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: var(--theme-highlight-background);
  color: var(--primary-text);
}

.p-toast-detail {
  color: white;
  margin: 0;
}

.p-fileupload .p-fileupload-content {
  padding: 1rem 0.5rem;
}

.p-fileupload .p-fileupload-row > div {
  padding: 0.5rem;
}

.p-message-detail.p-message-detail, .p-progressbar-label.p-progressbar-label {
  color: white;
}

.p-autocomplete-token-label, .p-autocomplete-item.p-highlight {
  color: white;
}
.p-autocomplete-token-icon {
  path {
    fill: white;
  }
}
.p-autocomplete-multiple-container {
  width: 100%;
}

.p-dropdown-panel, .p-dropdown-panel *, .p-dialog-content {
  overscroll-behavior: contain;
}

.p-dialog-content {
  padding: 1rem 1.5rem;
}

.p-accordion-header-text, .p-accordion-content {
  p {
    margin: 0;
  }
}

.p-disabled, .p-disabled * {
  cursor: not-allowed;
  pointer-events: auto;
  user-select: none;
}

.p-dropdown-panel {
  max-width: 100%;
}

.p-dropdown, .p-chips, .p-chips-multiple-container {
  width: 100%;
}

.p-password.p-inputwrapper {
  width: 100%;

  > div, input {
    width: 100%;
  }
}

.p-inputnumber-input[readonly] {
  background-color: #e1e1e1;
}

.p-inputgroup.flex-1 {
  align-items: center;
}
.flex-1 {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.mb-2 {
  margin-bottom: 4px;
}
.mb-8 {
  margin-bottom: 16px;
}
.mr-8 {
  margin-right: 16px;
}