.appPage {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 2;

    h1 {
        color: var(--primary-color);
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .appPageLogin__wrapper {
        h1 {
            text-align: center;
        }
    }
}

.appPage__pageHeader {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 28px;
    border-left: 4px solid var(--card-borderColor-color);

    h1, h2, h3 {
        margin: 0;
    }

    p, span {
        color: var(--text-color-secondary);
        font-size: 17.5px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin: 0;
    }

    span {
        margin-left: 10px;
        text-transform: uppercase;
    }

    span.companyName {
        margin: 0;
    }
}

.appPage__spacer {
    width: 100%;
    padding: 24px 0;
}

.appPageSection__hr {
    position: relative;
    width: 100%;
    padding: 0 0 0 30px;

    &:before {
        position: absolute;
        width: 100%;
        height: 1px;
        content: '';
        top: 50%;
        left: 0;
        z-index: 1;
        background-color: #E5E7EB;
    }

    span {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 0 10px;
        background-color: white;
        z-index: 9;
        position: relative;
    }
}

.appPage__content {
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.appPageSection {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    &.columns {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        width: 100%;

        /*> div {
            max-width: 50%;
        }*/
    }

    h2 {
        color: var(--global-textColor);
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0 0 24px;
    }

    h3 {
        color: var(--global-textColor);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0 0 16px;
    }

    .row {
        display: flex;
        gap: 1rem;
        align-items: center;
        padding: 5px 0;
        width: 100%;
    }

    .col {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}

.appPageSection__withBorder {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    padding: 17px;
    border-radius: 6px;
    border: 1px solid var(--card-borderColor-color);
    container-name: section_with_border;
    container-type: inline-size;

    &.grey {
        border-color: var(--table-border-color)
    }

    &.disabled {
        filter: grayscale(1);
        opacity: 0.8;
    }

    h2 {
        color: var(--global-textColor);
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0;
    }

    .row {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0;

        &.rowContent {
            padding: 7px 0;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.3rem;
        }

        p {
            margin: 0;
            /*flex: 1 1 100%;*/
        }

        ul, ol {
            padding-left: 1rem;

            li {
                color: var(--global-textColor);
            }
        }

        a {
            color: var(--global-textColor);
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        button {
            flex: 0 0 auto;
        }
    }

    &.columns {
        gap: 2em;
        column-count: 2;
        column-width: 4em;
        display: block;
        /*padding-bottom: 0;*/

        .appPageSection__pMeta {
            margin-bottom: 1em;
            break-inside: avoid;

            span:nth-of-type(1) {
                max-width: 30%;
            }
            span:nth-of-type(2) {
                max-width: 60%;
                text-align: right;
            }
        }
    }
}

.appPageSection__list {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    list-style: inside;
}

.appPageSection__listItem {
    padding: 15px;
    border-bottom: 1px solid var(--button-secondary-borderColor);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    &.row {
        flex-direction: row;
    }
}

.appPageSection__listItemRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
}

.appPageSection__checklist {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    div {
        display: flex;
        gap: 0.5rem;
    }
}

.appPageSection__iconActions {
    display: flex;
    gap: 0.5em;
}

.appPageSection__message {
    display: flex;
    align-items: center;
    gap: 20px;
    background: rgba(255, 242, 226, 0.7);
    border-style: solid;
    border-width: 0 0 0 6px;
    padding: 1.25rem 1.75rem;
    border-radius: 6px;

    &.warning {
        color: var(--message-warning-color);
        border-color: var(--message-warning-color);
    }

    &.info {
        color: var(--message-info-color);
        border-color: var(--message-info-color);
    }

    .summary {
        font-weight: bold;
    }

    a {
        color: inherit;
    }
}

@container section_with_border (max-width: 600px) {
    .appPageSection__withBorder {
        .row {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

.appPageSection__hero {
    display: flex;
    aspect-ratio: 16 / 9;
    width: 100%;
    @supports not (aspect-ratio: 16 / 9) {
        height: calc(100% * 9/16);
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.appPageSection__preview {
    padding: 17px 0;
    background: repeating-linear-gradient(
                    45deg,
                    transparent,
                    transparent 10px,
                    #f8d282 10px,
                    #f8d282 20px
    );

    .p-button-outlined {
        background: white;
    }
}

.appPageSection__row {
    display: flex;
    gap: 1rem;

    &.autoFlow {
        flex-wrap: wrap;
    }
}

.appPageSection__pMeta {
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    margin: 0;

    span {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    span:nth-of-type(2) {
        font-weight: 400;
    }
}

.appPageSection__table {
    width: 100%;
}

.appPageSection__titleClickable {
    width: 100%;
    height: 100%;
    display: block;

    &:hover {
        cursor: pointer;
    }
}

.appTableHeader {
    display: flex;
    justify-content: space-between;
}

.appPageSection__actions {
    display: flex;
    gap: 24px;
    padding: 24px 0 48px;
    flex-wrap: wrap;
}

.appPageSection__tableActions {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;

    &.lessGap {
        gap: 12px;
    }
}

.appPageSection__addToFavourites {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    color: var(--global-textColor);
    padding: 0;

    &:hover {
        cursor: pointer;
        color: var(--message-info-color);
    }

    &[data-active="true"] {
        background-color: var(--message-info-color);
        color: white;
    }

    &[disabled] {
        background-color: var(--message-info-background);
    }
}

.appPageSection__emailTemplate {
    > div {
        max-width: 100%!important;
        > div {
            max-width: 100%!important;
        }
    }
}

@media (max-width: 700px) {
    .appPageSection {
        &.columns {
            grid-template-columns: 1fr;
        }
    }
}
